/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FunctionComponent } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'gatsby';
import { content, image } from '../komponents/Privacy/Privacy.module.scss';
import privacy from '../../static/images/compra-con-terminal-movil.jpg';
import Header from '../komponents/Header/Header';
import Footer from '../komponents/Footer/Footer';

const About: FunctionComponent = () => (
  <>
    <Header />
    <div className={content}>
      <Image
        className={image}
        src={privacy}
        alt="Terminal punto de venta"
        title="Terminal punto de venta"
        rounded
      />
      <h1>¿Quienes somos?</h1>
      <p>
        En <Link to="/">PuntoTerminal</Link> queremos ayudarte a ti, empresario
        mexicano, a desarrollarte en el ámbito empresarial, ya sea que tengas
        una pequeña, mediana o grande empresa. PuntoTerminal busca ayudarte a
        actualizar tus métodos de pago y ofrecerte la mejor opción para tu
        negocio.
        <br />
        Buscamos reducir el costo de adquisición en Terminales Punto de Venta,
        por medio de una plataforma digital que compara el precio de los mejores
        proveedores de TPV en México entre ellos instituciones bancarias,
        procesadores de pago y organizaciones independientes.
        <br />
      </p>

      <p>
        Actualmente la era digital y el pago sin contacto están arrasando con
        las actualizaciones en métodos de pago, por lo que es imprescindible
        actualizar tu negocio y mejorar la experiencia de compra de tus
        clientes.
        <br />
        <br />
        México cuenta con una gran lista de proveedores, por ello suele ser un
        poco difícil comparar cada una de las terminales existentes, sus precios
        y los acuerdos de canje disponibles en el mercado. ¡Aquí entramos
        nosotros! A través de PuntoTerminal puedes fácilmente comparar todas las
        opciones que ofrece el mercado sin necesidad de invertir horas de tu día
        en ello.
        <br />
        <br />
        Con nosotros puedes reducir el costo de adquisición de tu nueva Terminal
        Punto de Venta, mejorar tus ganancias y ofrecer una mejor experiencia de
        compra a tus clientes, todo ello tras realizar una rápida comparación.
        <br />
        <br />
        Nuestro trabajo da a los empresarios la libertad de elegir la terminal
        de pago y el acuerdo que más les convenga en función de sus propias
        condiciones y preferencias, en este caso el cliente elige cual es la
        mejor terminal para su negocio y nosotros le mostramos los diferentes
        precios con los que cuenta el mercado, así como el porcentaje de ahorro
        que se obtiene comparando cada uno de ellos.
        <br />
        <br />
        <Link to="/">PuntoTerminal</Link> es el único sitio de comparación de
        precios dentro de la adquisición de Terminales Punto de Venta en México
        y le ofrecemos:
        <br />
        <br />
        <ul>
          <li>Comparación de cada tipo de Terminal Punto de Venta</li>
          <li>
            Comparación entre los precios de los mejores proveedores de
            Terminales Punto de Venta en México
          </li>
          <li>Porcentajes de ahorro con cada proveedor</li>
          <li>Comparación inmediata completamente gratis</li>
          <li>
            Ofrecemos una comparación objetiva, rápida y directa con completa
            transparencia
          </li>
          <li>Asesoría para elegir la terminal adecuada para su negocio</li>
        </ul>
        Nuestra estrategia en <Link to="/">PuntoTerminal</Link> es ofrecer un
        comparador gratuito y de alta calidad con la capacidad de comparar los
        diferentes precios de Terminales Punto de Venta de los mejores
        proveedores en México <br />
        Al ofrecer un servicio directo entre proveedores y comerciantes nos
        aseguramos de revolucionar el mercado Méxicano al aumentar la
        productividad de cada negocio y mejorar la experiencia del cliente.
      </p>

      <p>
        Buscamos un beneficio tanto para las pequeñas, medianas y grandes
        empresas como para los proveedores de pago (instituciones bancarias,
        procesadores de pago y organizaciones independientes). Con nuestro
        servicio obtienen una visión objetiva y simple de los acuerdos de canje
        de tarjetas disponibles en el mercado mexicano.
        <br />
        <br />
        Para los proveedores de pago, ofrecemos actualizaciones inmediatas de
        sus nuevos precios y productos, acceso a nuevos clientes de manera
        directa, rápida y eficiente. A través de PuntoTerminal, pueden dirigir
        ofertas y promociones específicas para todas las empresas interesadas en
        actualizar su método de pago.
        <br />
        <br />
        PuntoTerminal asegura completa transparencia hacia todos los
        involucrados en el servicio de comparación (empresarios comerciantes y
        proveedores de TPV), construyendo únicamente relaciones mercantiles que
        contribuyan al desarrollo del mercado Méxicano.
      </p>

      <h2>Nuestros clientes</h2>
      <p>
        Actualmente, PuntoTerminal ya ha presentado cotizaciones a pequeñas y
        medianas empresas en las que se ha logrado aumentar el número de
        clientes al mejorar su método de pago. Tenemos una amplia gama de
        proveedores con propuestas para comerciantes con diferentes
        orientaciones industriales.{' '}
      </p>
    </div>
    <Footer />
  </>
);

export default About;

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content="PuntoTerminal acerca de la empresa" />
    <title>Acerca de PuntoTerminal - PuntoTerminal.mx</title>
  </>
);
